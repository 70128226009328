import { useParams } from 'react-router-dom';
import {
  useDeletePoomsaePointMutation,
  useGetAthleteQuery,
  useUpdatePoomsaeProfileMutation,
} from '../../store';
import { useEffect } from 'react';
import classNames from 'classnames';
import Scrollbars from 'rc-scrollbars';
import Panel from '../uiComponents/Panel';
import {
  calculateAthletePoints,
  getAge,
  getPoomsaeProfile,
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import Tippy from '@tippyjs/react';
import { HiTrash } from 'react-icons/hi2';
import Notiflix from 'notiflix';
import { useSelector } from 'react-redux';
function AthletePoomsaeProfile() {
  const titleClasses = classNames('font-bold ');
  const { eventId, athleteId } = useParams();

  const { user } = useSelector((state) => state.authentication);

  const { data, isLoading, isError } = useGetAthleteQuery(athleteId);
  const [updateProfile, updateProfileResults] =
    useUpdatePoomsaeProfileMutation();

  const [deletePoomsaePoint, deletePoomsaePointResults] =
    useDeletePoomsaePointMutation();

  useEffect(() => {
    showLoadingEffect(data, isLoading, isError);
  }, [isLoading, isError, data]);

  useEffect(() => {
    showLoadingNotificationEffect(updateProfileResults, 'Athlete', 'Updating');
  }, [updateProfileResults]);

  useEffect(() => {
    showLoadingNotificationEffect(
      deletePoomsaePointResults,
      'Point',
      'Deleting'
    );
  }, [deletePoomsaePointResults]);

  if (data) {
    const athlete = data;

    const poomsaeProfile = getPoomsaeProfile(athlete, eventId);

    const handleViewNid = () => {
      window.open(
        // `https://antf.cloud/public/uploads/${athlete.nid}`,
        `${window.location.origin}/public/uploads/${athlete.nid}`,

        '_blank',
        'height=600,width=400'
      );
    };
    const handleApproveFee = () => {
      updateProfile({ profileId: poomsaeProfile._id, feePaid: true });
    };

    const handleCancelFee = () => {
      updateProfile({ profileId: poomsaeProfile._id, feePaid: false });
    };

    const handleDeletePoint = (roundId, pointId) => {
      Notiflix.Confirm.show(
        'Delete Point',
        'Are you sure you want to delete this point?',
        'Delete',
        'Cancel',
        () => deletePoomsaePoint({ roundId, pointId })
      );
    };

    const renderedPointsForRound = (profile, round, groupTitle) => {
      const pointArrToSort = [...(profile[round] || '')];
      const sortedJudges = pointArrToSort.sort((a, b) => {
        const jA = Object.keys(a)[2].split('j')[1];
        const jB = Object.keys(b)[2].split('j')[1];

        return jA - jB;
      });
      if (sortedJudges.length > 0) {
        const { avgAccuracy, avgPresentation, totalPoint } =
          calculateAthletePoints(profile, round);

        const points = sortedJudges.map((point) => {
          const judgeName = Object.keys(point)[2];

          return (
            <div
              key={point._id}
              className="flex flex-col gap-2 bg-gray-800 rounded shadow w-full p-4"
            >
              <div className="flex justify-between text-lg font-bold text-gray-100 text-center">
                <p className="">
                  {judgeName.charAt(0).toUpperCase() + judgeName.slice(1)} |{' '}
                  {point[judgeName].user.firstName}
                </p>
                {user.role === 'admin' && (
                  <Tippy content="Delete">
                    <button
                      onClick={() =>
                        handleDeletePoint(point._id, point[judgeName]._id)
                      }
                    >
                      <HiTrash />
                    </button>
                  </Tippy>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between gap-2 font-bold text-gray-100 text-center">
                  <div className="bg-green-500 w-full p-2 rounded">
                    <p>{point[judgeName].accuracyPoints.toFixed(2)}</p>
                  </div>
                  <div className="bg-red-500 w-full p-2 rounded">
                    <p>{point[judgeName].presentationPoints.toFixed(2)}</p>
                  </div>
                </div>
                <div className="bg-green-500 p-2 rounded text-center font-bold text-gray-100">
                  <p>
                    {(
                      point[judgeName].presentationPoints +
                      point[judgeName].accuracyPoints
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          );
        });

        return (
          <div>
            <p className="font-bold text-gray-800 text-xl mb-2">{groupTitle}</p>
            <div className="grid grid-cols-2 grid-rows-2 md:grid-rows-1 md:grid-cols-6 gap-4">
              {points}{' '}
              <div className="md:col-start-6 flex flex-col gap-2 bg-gray-800 rounded shadow w-full p-4">
                <div>
                  <p className="text-lg font-bold text-gray-100 text-center">
                    Result
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between gap-2 font-bold text-gray-100 text-center">
                    <div className="bg-green-500 w-full p-2 rounded">
                      <p>{avgAccuracy?.toFixed(2) ?? `0.00`}</p>
                    </div>
                    <div className="bg-red-500 w-full p-2 rounded">
                      <p>{avgPresentation?.toFixed(2) ?? `0.00`}</p>
                    </div>
                  </div>
                  <div className="bg-green-500 p-2 rounded text-center font-bold text-gray-100">
                    <p>
                      {isNaN(totalPoint?.toFixed(3))
                        ? `0.000`
                        : totalPoint?.toFixed(3)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    };

    const renderedPreliminaryPoints = renderedPointsForRound(
      poomsaeProfile,
      'preliminaryPoints',
      'Preliminary'
    );

    const renderedSemiFinalPoints = renderedPointsForRound(
      poomsaeProfile,
      'semiFinalPoints',
      'Semi Final'
    );

    const renderedFinalPoints = renderedPointsForRound(
      poomsaeProfile,
      'finalPoints',
      'Final'
    );

    return (
      <div className="grid gap-4 grid-cols-5 grid-rows-[1fr_1fr_1fr] md:grid-rows-[230px_1fr] h-max md:h-full">
        <div className="grid row-start-2 col-start-1 col-end-6 md:row-start-1 md:col-start-1 md:col-end-5 p-4 drop-shadow-md rounded-md bg-gray-200">
          <div className="flex flex-col md:flex-row md:justify-between gap-2">
            <Scrollbars className="rounded-md">
              <div className="grid grid-cols-2 gap-y-2 w-fit md:grid-cols-[1fr_1fr] grid-rows-3 md:w-full gap-x-4 md:gap-x-0">
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Name (English):</h3>
                  <p
                    className={`${
                      athlete.fullNameEn.length > 20
                        ? 'text-[0.8rem] pt-1 font-semibold'
                        : ''
                    }`}
                  >
                    {athlete.fullNameEn}
                  </p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  {' '}
                  <h3 className={titleClasses}>Name (Native):</h3>
                  <p className="font-pashto font-bold text-lg">
                    {athlete.fullNameDr}
                  </p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Date of Birth:</h3>
                  <p>
                    {new Date(athlete.dateOfBirth).toLocaleDateString('en-gb')}{' '}
                    ({getAge(athlete.dateOfBirth)})
                  </p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  {' '}
                  <h3 className={titleClasses}>Gender:</h3>
                  <p>
                    {athlete.gender[0].toUpperCase() + athlete.gender.slice(1)}
                  </p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Club:</h3>
                  <p>{athlete.club.name}</p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Category</h3>
                  <p>{getPoomsaeProfile(athlete, eventId).category}</p>
                </div>

                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Country:</h3>
                  <p>{athlete.club.country}</p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Province:</h3>
                  <p>{athlete.club.province}</p>
                </div>

                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Status:</h3>
                  <p
                    className={`font-bold ${
                      poomsaeProfile.feePaid === false
                        ? 'text-red-500'
                        : 'text-green-500'
                    }`}
                  >
                    {poomsaeProfile.feePaid ? 'Approved' : 'Pending'}
                  </p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Current Dan:</h3>
                  <p>{athlete.danGradesKukkiwon?.slice(-1)[0]?.dan || 'N/A'}</p>
                </div>
                <div className="grid grid-cols-2 h-min">
                  <h3 className={titleClasses}>Belt:</h3>
                  <p>
                    {athlete.belt.charAt(0).toUpperCase() +
                      athlete.belt.slice(1)}
                  </p>
                </div>
              </div>
            </Scrollbars>

            <div className="hidden md:flex gap-2 items-center w-1/6">
              {user.role !== 'trainer' && (
                <div className="flex flex-col gap-2 w-full">
                  {poomsaeProfile.feePaid ? (
                    <button
                      onClick={handleCancelFee}
                      className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      onClick={handleApproveFee}
                      className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
                    >
                      Approve
                    </button>
                  )}

                  <button
                    onClick={handleViewNid}
                    className="uppercase text-gray-200 font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
                  >
                    View NID
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Panel className="flex justify-between md:justify-center row-start-1 col-start-1 col-end-6 md:row-start-1 md:col-start-5">
          <div className="h-full grid items-center justify-center align-center">
            <img
              className=" w-32 aspect-[3/4] rounded-lg shadow-lg self-center"
              // src={`https://antf.cloud/public/uploads/${athlete.photo}`}
              src={`${window.location.origin}/public/uploads/${athlete.photo}`}
              alt={`${athlete.fullNameEn}`}
            />
          </div>
          {user.role !== 'trainer' && (
            <div className="flex flex-col items-center justify-center gap-4 md:hidden w-1/2">
              {poomsaeProfile.feePaid ? (
                <button
                  onClick={handleCancelFee}
                  className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={handleApproveFee}
                  className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
                >
                  Approve
                </button>
              )}

              <button
                onClick={handleViewNid}
                className="uppercase text-gray-200 font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
              >
                View NID
              </button>
            </div>
          )}
        </Panel>
        <Panel className="row-start-3 md:row-start-2 col-start-1 col-end-6 h-full ">
          <Scrollbars className="rounded-md">
            <div className="grid gap-4 auto-rows-min">
              {renderedPreliminaryPoints}
              {renderedSemiFinalPoints}
              {renderedFinalPoints}
              {!renderedPreliminaryPoints &&
                !renderedSemiFinalPoints &&
                !renderedFinalPoints && (
                  <div className="w-full h-full  flex items-center justify-center">
                    <h1 className="font-bold text-center text-gray-800 text-xl">
                      Competition Pending.
                    </h1>
                  </div>
                )}
            </div>
          </Scrollbars>
        </Panel>
      </div>
    );
  }
}

export default AthletePoomsaeProfile;

import { useParams } from 'react-router-dom';
import { useGetAthleteQuery, useUpdateAthleteMutation } from '../../store';
import { useEffect } from 'react';
import classNames from 'classnames';
import Scrollbars from 'rc-scrollbars';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import KukkiwonApplication from '../kukkiwonApplication/KukkiwonApplication';
import Panel from '../uiComponents/Panel';
import { useUpdateExamProfileMutation } from '../../store';
import {
  getAge,
  getExaminationProfile,
  showLoadingNotificationEffect,
} from '../../utils/helpers';
import { useSelector } from 'react-redux';
import Skeleton from '../uiComponents/Skeleton';
function AthleteExamProfile() {
  const { eventId, athleteId } = useParams();

  const { user } = useSelector((state) => state.authentication);
  const [updateProfile, updateProfileResults] = useUpdateExamProfileMutation();
  const [updateAthlete] = useUpdateAthleteMutation();

  const { data, isLoading, isError, error } = useGetAthleteQuery(athleteId);

  const titleClasses = classNames('font-bold ');

  useEffect(() => {
    // showLoadingEffect(data, isLoading, isError, error);
  }, [isLoading, isError, data, error]);

  useEffect(() => {
    showLoadingNotificationEffect(updateProfileResults, 'Athlete', 'Updating');
  }, [updateProfileResults]);

  const athlete = data;

  const handleViewNid = () => {
    window.open(
      // `https://antf.cloud/public/uploads/${athlete.nid}`,
      `${window.location.origin}/public/uploads/${athlete.nid}`,

      '_blank',
      'height=600,width=400'
    );
  };
  const handleApproveFee = () => {
    const profileId = getExaminationProfile(athlete, eventId)[0]._id;
    updateProfile({ profileId, feePaid: true });
  };

  const handleCancelFee = () => {
    const profileId = getExaminationProfile(athlete, eventId)[0]._id;
    updateProfile({ profileId, feePaid: false });
  };

  const handleLockUnlockAthleteProfile = (trueFalse) => {
    updateAthlete({ id: athleteId, body: { profileLocked: trueFalse } });
  };

  let examinationProfile = {};
  let examProfiles = Array.from({ length: 10 }).map((_, i) => {
    return (
      <div key={i}>
        <div
          className={`flex justify-between md:flex-col text-white font-bold rounded shadow h-32`}
        >
          {' '}
          <Skeleton />
        </div>
      </div>
    );
  });

  if (data) {
    examinationProfile = getExaminationProfile(athlete, eventId)[0];

    const testsArray = Object.entries(examinationProfile.promotionTests);

    examProfiles = testsArray.map((test, i) => {
      const title = `${test[0].split(/(?=[A-Z])/)[0][0].toUpperCase()}${test[0]
        .split(/(?=[A-Z])/)[0]
        .slice(1)} ${
        test[0].split(/(?=[A-Z])/)[1] ? test[0].split(/(?=[A-Z])/)[1] : ''
      } ${test[0].split(/(?=[A-Z])/)[2] ? test[0].split(/(?=[A-Z])/)[2] : ''}`;
      return (
        <div key={i}>
          <div
            className={`flex p-4 justify-between md:flex-col ${
              test[1].score >= 6 ? `bg-gray-700` : `bg-red-500`
            } text-white font-bold py-5 rounded shadow`}
          >
            <h3 className="md:text-center">{title}</h3>
            <p className="md:text-7xl text-center">{test[1].score}</p>
          </div>
        </div>
      );
    });
  }

  // console.log(athlete);
  return (
    <div className="grid gap-4 grid-cols-5 grid-rows-[1fr_1fr_1fr] md:grid-rows-[230px_1fr] h-max md:h-full">
      <div className="grid row-start-2 col-start-1 col-end-6 md:row-start-1 md:col-start-1 md:col-end-5 p-4 drop-shadow-md rounded-md bg-gray-200">
        <div className="flex flex-col md:flex-row md:justify-between gap-2">
          <Scrollbars className="rounded-md">
            <div className="grid grid-cols-2 gap-y-2 w-fit md:grid-cols-[1fr_1fr] md:grid-rows-3 md:w-full gap-x-4 md:gap-x-0">
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Name (English):</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p className={`${athlete.fullNameEn.length > 20 ? '' : ''}`}>
                    {athlete.fullNameEn}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                {' '}
                <h3 className={titleClasses}>Name (Native):</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p className="font-pashto font-bold text-lg">
                    {athlete.fullNameDr}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Date of Birth:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>
                    {new Date(athlete.dateOfBirth).toLocaleDateString('en-gb')}{' '}
                    ({getAge(athlete.dateOfBirth)})
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                {' '}
                <h3 className={titleClasses}>Gender:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>
                    {athlete.gender[0].toUpperCase() + athlete.gender.slice(1)}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Club:</h3>
                {isLoading ? <Skeleton /> : <p>{athlete.club.name}</p>}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Applied Dan:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>{getExaminationProfile(athlete, eventId)[0].appliedDan}</p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Current Dan:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>{athlete.danGradesKukkiwon?.slice(-1)[0]?.dan || 'N/A'}</p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Current Dan No:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>
                    {athlete.danGradesKukkiwon?.slice(-1)[0]?.danCertificate ||
                      'N/A'}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Country:</h3>
                {isLoading ? <Skeleton /> : <p>{athlete.club.country}</p>}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Province:</h3>
                {isLoading ? <Skeleton /> : <p>{athlete.club.province}</p>}
              </div>

              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Status:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p
                    className={`font-bold ${
                      getExaminationProfile(athlete, eventId)[0].feePaid ===
                      false
                        ? 'text-red-500'
                        : 'text-green-500'
                    }`}
                  >
                    {getExaminationProfile(athlete, eventId)[0].feePaid
                      ? 'Approved'
                      : 'Pending'}
                  </p>
                )}
              </div>
            </div>
          </Scrollbars>
          <div className="gap-2 hidden md:flex items-center w-1/6">
            {user.role !== 'trainer' && (
              <div className="flex flex-col gap-2 w-full">
                {user.role === 'admin' &&
                  (athlete?.profileLocked ? (
                    <button
                      onClick={() => handleLockUnlockAthleteProfile(false)}
                      className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
                    >
                      Unlock
                    </button>
                  ) : (
                    <button
                      onClick={() => handleLockUnlockAthleteProfile(true)}
                      className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
                    >
                      Lock
                    </button>
                  ))}
                {data && getExaminationProfile(athlete, eventId)[0].feePaid ? (
                  <button
                    disabled={isLoading}
                    onClick={handleCancelFee}
                    className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    disabled={isLoading}
                    onClick={handleApproveFee}
                    className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
                  >
                    Approve
                  </button>
                )}

                <button
                  disabled={isLoading}
                  onClick={handleViewNid}
                  className="uppercase text-gray-100 font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
                >
                  View NID
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Panel className="flex justify-between md:justify-center row-start-1 col-start-1 col-end-6 md:row-start-1 md:col-start-5">
        <div className="h-full grid items-center justify-center align-center">
          <img
            className=" w-32 aspect-[3/4] rounded-lg shadow-lg self-center"
            // src={`https://antf.cloud/public/uploads/${athlete.photo}`}
            src={`${window.location.origin}/public/uploads/${athlete?.photo}`}
            alt={`${athlete?.fullNameEn}`}
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-4 md:hidden w-1/2">
          {data &&
          getExaminationProfile(athlete, eventId)[0].feePaid === false ? (
            <button
              onClick={handleApproveFee}
              className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
            >
              Approve
            </button>
          ) : (
            <button
              onClick={handleCancelFee}
              className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
            >
              Cancel
            </button>
          )}
          <button
            onClick={handleViewNid}
            className="uppercase text-gray-200 font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
          >
            View NID
          </button>
          {/* {getExaminationProfile(athlete, eventId)[0].promotionStatus ===
              'passed' && (
              <PDFDownloadLink
                document={<KukkiwonApplication athlete={athlete} />}
                fileName={`${athlete.firstNameEn.toUpperCase()}-${
                  athlete.middleNameEn
                    ? athlete.middleNameEn.toUpperCase() + `-`
                    : ''
                }${athlete.lastNameEn.toUpperCase()}-DAN-${
                  getExaminationProfile(athlete, eventId)[0].appliedDan
                }`}
              >
                {({ loading }) => {
                  return loading ? (
                    <button
                      // onClick={() => athlete}
                      className="uppercase text-white font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
                    >
                      Loading
                    </button>
                  ) : (
                    <button
                      // onClick={() => athlete}
                      className="uppercase text-white font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
                    >
                      Application
                    </button>
                  );
                }}
              </PDFDownloadLink>
            )} */}
        </div>
      </Panel>
      <Panel className="row-start-3 col-start-1 col-end-6 md:row-start-2  md:col-end-6 md:h-full">
        <Scrollbars className="rounded-md">
          <h3 className="text-xl font-bold text-gray-800 mb-2">
            Promotion Tests for Dan {examinationProfile.appliedDan}
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-5 md:grid-rows-2 gap-2 mb-2">
            {examProfiles}
          </div>
        </Scrollbars>
      </Panel>
    </div>
  );
}

export default AthleteExamProfile;

import { useParams } from 'react-router-dom';
import { useGetAthleteQuery, useUpdateAthleteMutation } from '../../store';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Scrollbars from 'rc-scrollbars';
import Panel from '../uiComponents/Panel';
import { getAge } from '../../utils/helpers';
import CertificatesDetail from './CertificatesDetail';
import ExaminationsDetail from './ExaminationsDetail';
import PoomsaeDetail from './PoomsaeDetail';
import { useSelector } from 'react-redux';
import Skeleton from '../uiComponents/Skeleton';
function AthletePoomsaeProfile() {
  const titleClasses = classNames('font-bold ');
  const { athleteId } = useParams();

  const { user } = useSelector((state) => state.authentication);
  const [showCertificates, setShowCertificates] = useState(true);
  const [showExaminations, setShowExaminations] = useState(false);
  const [showPoomsae, setShowPoomsae] = useState(false);

  const { data, isLoading, isError } = useGetAthleteQuery(athleteId);
  const [updateAthlete] = useUpdateAthleteMutation();

  useEffect(() => {
    // showLoadingEffect(data, isLoading, isError);
  }, [isLoading, isError, data]);

  const athlete = data || {};

  const handleViewNid = () => {
    window.open(
      // `https://antf.cloud/public/uploads/${athlete.nid}`,
      `${window.location.origin}/public/uploads/${athlete.nid}`,

      '_blank',
      'height=600,width=400'
    );
  };

  const handleLockUnlockAthleteProfile = (trueFalse) => {
    updateAthlete({ id: athleteId, body: { profileLocked: trueFalse } });
  };

  return (
    <div className="flex flex-col gap-4 md:grid md:grid-rows-[230px_1fr] h-max md:h-full">
      <Panel className="grid row-start-2 col-start-1 col-end-6 md:row-start-1 md:col-start-1 md:col-end-5 md:h-full">
        <div className="flex flex-col md:flex-row md:justify-between gap-2 h-96 md:h-full">
          <Scrollbars className="rounded-md">
            <div className="grid grid-cols-2 gap-y-2 w-full h-full md:grid-cols-[1fr_1fr] md:grid-rows-3 md:w-full gap-x-4 md:gap-x-0">
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Name (English):</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p className={`${athlete.fullNameEn.length > 20 ? '' : ''}`}>
                    {athlete.fullNameEn}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                {' '}
                <h3 className={titleClasses}>Name (Native):</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p className="font-pashto font-bold text-lg">
                    {athlete.fullNameDr}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Date of Birth:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>
                    {new Date(athlete.dateOfBirth).toLocaleDateString('en-gb')}{' '}
                    ({getAge(athlete.dateOfBirth)})
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                {' '}
                <h3 className={titleClasses}>Gender:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>
                    {athlete.gender[0].toUpperCase() + athlete.gender.slice(1)}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Club:</h3>
                {isLoading ? <Skeleton /> : <p>{athlete.club.name}</p>}
              </div>

              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Current Dan:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>{athlete.danGradesKukkiwon?.slice(-1)[0]?.dan || 'N/A'}</p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Current Dan No:</h3>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <p>
                    {athlete.danGradesKukkiwon?.slice(-1)[0]?.danCertificate ||
                      'N/A'}
                  </p>
                )}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Country:</h3>
                {isLoading ? <Skeleton /> : <p>{athlete.club.country}</p>}
              </div>
              <div className="grid grid-cols-2 h-min">
                <h3 className={titleClasses}>Province:</h3>
                {isLoading ? <Skeleton /> : <p>{athlete.club.province}</p>}
              </div>
              {user.role === 'admin' && (
                <>
                  <div className="grid grid-cols-2 h-min">
                    <h3 className={titleClasses}>Tcon Username:</h3>
                    {isLoading ? <Skeleton /> : <p>{athlete.tcon?.username}</p>}
                  </div>
                  <div className="grid grid-cols-2 col-start-2 h-min">
                    <h3 className={titleClasses}>Tcon Password:</h3>
                    {isLoading ? <Skeleton /> : <p>{athlete.tcon?.password}</p>}
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
          <div className="gap-2 hidden md:flex  w-1/6">
            <div className="flex flex-col gap-2 w-full">
              <button
                onClick={handleViewNid}
                disabled={isLoading}
                className="uppercase text-gray-100 font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
              >
                View NID
              </button>
              {user.role === 'admin' &&
                (athlete?.profileLocked ? (
                  <button
                    disabled={isLoading}
                    onClick={() => handleLockUnlockAthleteProfile(false)}
                    className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
                  >
                    Unlock
                  </button>
                ) : (
                  <button
                    onClick={() => handleLockUnlockAthleteProfile(true)}
                    disabled={isLoading}
                    className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
                  >
                    Lock
                  </button>
                ))}
            </div>
          </div>
        </div>
      </Panel>
      <Panel
        id="athlete-image-panel"
        className="flex justify-between md:justify-center md:row-start-1 md:col-start-5"
      >
        <div className="h-full grid items-center justify-center align-center">
          {isLoading ? (
            <div className="w-32 aspect-[3/4] rounded-lg shadow-lg self-center">
              {' '}
              <Skeleton />
            </div>
          ) : (
            <img
              className=" w-32 aspect-[3/4] rounded-lg shadow-lg self-center"
              // src={`https://antf.cloud/public/uploads/${athlete.photo}`}
              src={`${window.location.origin}/public/uploads/${athlete.photo}`}
              alt={`${athlete.fullNameEn}`}
            />
          )}
        </div>
        <div className="flex flex-col items-center justify-center gap-4 md:hidden w-1/2">
          <button
            disabled={isLoading}
            onClick={handleViewNid}
            className="uppercase text-gray-100 font-bold rounded shadow bg-gray-700 hover:bg-gray-800 transition px-3 py-2 w-full"
          >
            View NID
          </button>
          {user.role === 'admin' &&
            (athlete?.profileLocked ? (
              <button
                disabled={isLoading}
                onClick={() => handleLockUnlockAthleteProfile(false)}
                className="uppercase text-red-600 font-bold rounded shadow bg-red-100 hover:bg-red-200 transition px-3 py-2 w-full"
              >
                Unlock
              </button>
            ) : (
              <button
                disabled={isLoading}
                onClick={() => handleLockUnlockAthleteProfile(true)}
                className="uppercase text-green-600 font-bold rounded shadow bg-green-100 hover:bg-green-200 transition px-3 py-2 w-full"
              >
                Lock
              </button>
            ))}
        </div>
      </Panel>

      <Panel className="relative mt-8 row-start-3 col-start-1 col-end-6 h-96 md:row-start-2  md:col-end-6 md:h-full">
        <div className="absolute flex gap-2 -top-[2.4rem] left-0">
          <div
            onClick={() => {
              setShowCertificates(true);
              setShowExaminations(false);
              setShowPoomsae(false);
            }}
            className={`bg-gray-200 rounded-full px-3 py-1 font-semibold text-sm cursor-pointer ${
              showCertificates &&
              'bg-gray-600 text-white shadow-white transition-all'
            }`}
          >
            Certificates
          </div>
          <div
            onClick={() => {
              setShowCertificates(false);
              setShowExaminations(true);
              setShowPoomsae(false);
            }}
            className={`bg-gray-200 rounded-full px-3 py-1 font-semibold text-sm cursor-pointer ${
              showExaminations &&
              'bg-gray-600 text-white shadow-white transition-all'
            }`}
          >
            Examinations
          </div>
          <div
            onClick={() => {
              setShowCertificates(false);
              setShowExaminations(false);
              setShowPoomsae(true);
            }}
            className={`bg-gray-200 rounded-full px-3 py-1 font-semibold text-sm cursor-pointer ${
              showPoomsae &&
              'bg-gray-600 text-white shadow-white transition-all'
            }`}
          >
            Poomsae
          </div>
        </div>
        <Scrollbars>
          {showCertificates && data && <CertificatesDetail athlete={athlete} />}
          {showExaminations && <ExaminationsDetail athlete={athlete} />}
          {showPoomsae && <PoomsaeDetail athlete={athlete} />}
        </Scrollbars>
      </Panel>
    </div>
  );
}

export default AthletePoomsaeProfile;
